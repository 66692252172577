import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {

    const getCurrentYear = () => {
        return new Date().getFullYear();
    }

    let activeStyle = {
        color: "#2c3e50"
    };



    return (
        <div className='sidebar'>
            <div className="id">
                <div className="idContent">
                    <img src="./media/gaiakhouas.jpeg" alt="" />
                    <h3>Gaïa KHOUAS</h3>
                    <p className='slogan'>"Imagine, create and inspire people"</p>
                </div>
            </div>
            <div className="navigation">
                <ul>
                    <li>
                        <NavLink exact to="/" style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                        }  >
                            <i   className="fas fa-home" ></i>
                            <span  > Accueil</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/Connaissances" style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                        }  >
                            <i className="fas fa-mountain"></i>
                            <span> Connaissances</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/portfolio" style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                        } >
                            <i className="fas fa-images"></i>
                            <span> Portfolio</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/contact" style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                        } >
                            <i className="fas fa-book"></i>
                            <span> Contact</span>
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className="socialNetwork">
                <ul>
                    <li>
                        <a href="https://www.linkedin.com/in/gkhouas/" target="_blank"
                            rel="noopener  noreferrer" ><i className="fab fa-linkedin" ></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/gaiakhouas" target="_blank"
                            rel="noopener  noreferrer" ><i className="fab fa-github" ></i>
                        </a>
                    </li>
                </ul>
                <div className="signature">
                    <p>Réalisé avec React &copy; Copyright {getCurrentYear()}  </p>
                </div>
            </div>

        </div>


    );
};

export default Navigation;