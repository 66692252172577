import React from 'react';


const Experience = () => {
    return (
        <div className='experience'>
            <h3 className='exp animate__animated animate__fadeIn'>Expérience</h3>
            <div className="exp1 animate__animated animate__fadeIn">

                <h4><img className='expImg' src="https://media-exp2.licdn.com/dms/image/C4D0BAQGGayBWKgjrMg/company-logo_100_100/0/1601901007738?e=1663200000&v=beta&t=E2lmLOzyc4kG7MmmYPAy__hCYoTGnaqMEhcpYVhbY-o" alt="logo mh" /> Creative developer</h4>
                <h5>oct. 2020 - aujourd’hui - Malakoff humanis</h5>
                <ul>
                    <li>- Conception ux/ui de la base documentaire groupe Eurêka (wireframes, maquettes, user stories, workflows, pocs)</li>
                    <li>- Automatisation de process métier via workflow (power automate)</li>
                    <li>- Création et administration du groupe teams Eurêka</li>
                    <li>- Développement/refonte de sites web (SharePoint)</li>
                    <li>- Développement de services web (carte interactive en JS)</li>
                    <li>- Développement d'une application pour le pilotage de la direction de communication (react + firebase : en cours)</li>
                    <li>- Support à l'intégration html des newsletters et emailing</li>
                    <li>- Assistance technique</li>
                </ul>
            </div>
            <div className="exp2 animate__animated animate__fadeIn">
                <h4><img className='expImg' src="https://media-exp2.licdn.com/dms/image/C4D0BAQGGayBWKgjrMg/company-logo_100_100/0/1601901007738?e=1663200000&v=beta&t=E2lmLOzyc4kG7MmmYPAy__hCYoTGnaqMEhcpYVhbY-o" alt="logo mh" /> Développeur / intégrateur web</h4>
                <h5>sept. 2019 - août 2020 - Malakoff humanis</h5>
                <ul>
                    <li>- Développement d'un site en sharepoint pour le pôle fabrication des contenus </li>
                    <li>- Intégration de newsletters B2B de l'équipe de conception via le code de balisage html et le style css.</li>
                    <li>- Refonte complète de la base doc mysesam (modélisation merise / stacks : oop php/mysql, jquery/ajax, datatable, js, html5, bootstrap)</li>
                </ul>
            </div>
            <div className="exp3 animate__animated animate__fadeIn">
                <h4><img className='expImg' src="https://cdn-icons-png.flaticon.com/512/1803/1803671.png" alt="" /> Développeur Indépendant</h4>
                <h5>sept. 2015 - sept. 2019</h5>
                <h6>Conception </h6>
                <ul>
                    <li>- Analyse des besoins clients</li>
                    <li>- Aide à la rédaction du cahier des charges</li>
                    <li>- Maquettage des différentes pages du site</li>
                    <li>- Gestion du projet avec la méthode agile</li>
                </ul>

                <h6>Seo/Développement/Recette/Production : </h6>
                <ul>
                    <li>- Référencement naturel et optimisation (google adwords on keywords planner, google trends, google analytics, search console)</li>
                    <li>- Balisage html et gestion des styles avec css</li>
                    <li>- Responsive design avec le framework Bootstrap</li>
                    <li>- Création des bases de données avec mysql</li>
                    <li>- Gestion des mises à jour via le protocole ftp sous fizella</li>
                </ul>
            </div>
            <div className="exp4 animate__animated animate__fadeIn">
                <h4><img className='expImg' src="https://media-exp2.licdn.com/dms/image/C4D0BAQE03SnyW0k46A/company-logo_100_100/0/1589911359277?e=1663200000&v=beta&t=mEKUQKcMhMjouauU_LuhNb9wExuS5xxdEkX3o_3fbJ8" alt="logo beachfront" /> BA immobilier</h4>
                <h5>janv. 2015 - aout. 2015 - Beachfront Realty</h5>
                <ul>
                    <li>- Gestion des demandes clients (rent/buy)</li>
                    <li>- Gestion des visites (showing properties)</li>
                    <li>- Recherche de listing expirés (expired listings) via le MLS.</li>
                    <li>- Prospection de nouveaux clients (France, Canada) pour achat/location de propriétés.</li>
                    <li>- Création d'une base de données créee sous Access en vue d'analyser et de proposer des propriétés à fort potentiel économique.</li>
                </ul>
            </div>
            <div className="exp5 animate__animated animate__fadeIn">
                <h4><img className='expImg' src="https://media-exp2.licdn.com/dms/image/C4E0BAQEPZFBE73Nylw/company-logo_100_100/0/1654068114114?e=1663200000&v=beta&t=pUkizyun5z_uwMbWseX1MP9ny5ucqWwyirjet6N4qs8" alt="logo accor" /> Data Analyst/Administrateur de base de données CRM</h4>
                <h5>sept. 2013 - sept. 2014 - Accor Hotel</h5>
                <h6>Analyse des données CRM</h6>
                <ul>
                    <li>- Développement d'un outil d'analyse de données</li>
                    <li>- Analyse des données CRM & reapplication (Data Quality)</li>
                    <li>- Reporting d'optimisation SI (Analyses des bugs sur Quality Center)</li>
                    <li>- Administration (B2B, B2C & Data PRIVACY report)</li>
                    <li>- Indicateurs TMA</li>
                </ul>
                <h6>Administration des données B2B </h6>
                <ul>
                    <li>- Support Requests sur l'IHM Sales Forces (Mise à jour de contacts et de comptes et création de TIDS) </li>
                    <li>- Codification de codes DUNS (Matching & Enrichment) via dnb.com</li>
                    <li>- Trigger B2B (lancement et checking de validation)</li>
                </ul>
            </div>
            <div className="exp6 animate__animated animate__fadeIn">
                <h4><img className='expImg' src="https://media-exp2.licdn.com/dms/image/C4E0BAQHkwXQh88jQwA/company-logo_100_100/0/1627559951146?e=1663200000&v=beta&t=WsQPoFhacGclelZdi3YjxKDiYim7ynFmyyEWBNoPm50" alt="" /> Data Analyst/Administrateur Facturation Roaming</h4>
                <h5>sept. 2011 - sept. 2013 - Bouygues Télécoms </h5>
                <h6>Gestion d'un portefeuille opérateur roaming</h6>
                <ul>
                    <li>- Administration des relances de paiement (America, Asie, Europe)</li>
                    <li>- Préparation des régulations clients</li>
                </ul>
                <h6>Gestion des factures</h6>
                <ul>
                    <li>- Création </li>
                    <li>- Réconciliation </li>
                    <li>- Gestion du traçage des paiements à recevoir</li>
                </ul>
                <h6>Optimisation du processus de facturation roaming</h6>
                <ul>
                    <li>- Analyse d'indicateurs pertinents depuis le processus de recouvrement</li>
                    <li>- Reporting </li>
                    <li>- Optimisation du temps lié au recouvrement du processus roaming</li>
                </ul>
            </div>
        </div>
    );
}

export default Experience;