export const portfolioData = [
  {
    id: 1,
    name: 'mydemenageur.com',
    languages: ['js', 'php','css'],
    languagesIcons: ['fab fa-php', 'fab fa-js','fab fa-css3-alt'],
    source: 'https://app.mydemenageur.com/',
    info: 'Plateforme de mise en relation entre déménageurs et particuliers',
    picture: './media/project1.JPG'
  },
  {
    id: 2,
    name: 'easyForm',
    languages: ['php','laravel', 'css'],
    languagesIcons: ['fab fa-php', 'fab fa-css3-alt', 'fab fa-laravel'],
    source: 'https://github.com/gaiakhouas/easyform',
    info: 'Plateforme de cours en ligne avec solution de paiement intégrée (stripe).',
    picture: './media/project2.JPG'
  },
  {
    id: 3,
    name: 'biblioTech',
    languages: ['js', 'css', 'react'],
    languagesIcons: ['fab fa-node','fab fa-react'],
    source: 'https://gitlab.com/Max555dom/projectnodereact',
    info: 'Une simple CRUD pour publier des livres en ligne avec la stack MERN.',
    picture: './media/project3.JPG'
  },
  {
    id: 4,
    name: 'Carte de france interactive',
    languages: ['js', 'css'],
    languagesIcons: ['fab fa-js', 'fab fa-css3-alt'],
    source: 'https://github.com/gaiakhouas/interactive_map',
    info: 'Une carte interactive avec markers codée en js et jquery (sans google map api).',
    picture: './media/project4.JPG'
  },
  {
    id: 5,
    name: 'proJet',
    languages: ['react', 'css', 'firebase'],
    languagesIcons: ['fab fa-react','fab fa-css3-alt', 'fab fa-css3-firebase'],
    source: 'http://www.github.com',
    info: 'Un site de gestion de projet avec un tiers partie (stripe) en utilisant les cloud functions de GCP, le web hosting de firbase, et la base de données firestore.',
    picture: './media/project5.JPG'
  },
  {
    id: 6,
    name: 'Covid death\'s factors',
    languages: ['python'],
    languagesIcons: ['fab fa-python','fab fa-panda'],
    source: 'https://github.com/gaiakhouas/covid19-data-analysis/blob/main/notebook%20project%20.ipynb',
    info: 'Un notebook de data analysis sur les potentiels facteurs favorisant le décès de la covid-19. Le fichier a été crée via les librairies panda, numpy, matplotlib.pyplot et seaborn à partir d\'un dataset du site kaggle.com.',
    picture: './media/project6.JPG'
  },
  {
    id: 7,
    name: 'Netflix Roulette',
    languages: ['flutter'],
    languagesIcons: ['fab fa-google'],
    source: 'https://github.com/gaiakhouas/netflix_roulette',
    info: 'Un service permettant d\'afficher dynamiquement les images de films provenant de l\'api de netflix.',
    picture: './media/project7.gif'
  },
  {
    id: 8,
    name: 'hotelcop',
    languages: ['html', 'js','css'],
    languagesIcons: ['fab fa-html', 'fab fa-js','fab fa-css3-alt'],
    source: 'https://github.com/gaiakhouas/hotelcop',
    info: 'Site static d\'un hôtel de luxe fictif se trouvant au centre de paris.',
    picture: './media/project0.JPG'
  },
  {
    id: 9,
    name: 'switch',
    languages: ['js', 'php','css', 'html'],
    languagesIcons: ['fab fa-js','fab fa-php','fab fa-css3-alt'],
    source: 'https://github.com/gaiakhouas/switch',
    info: 'Une site de location de salle permettant à l\'utilisateur d\'ajouter son contenu (texte et image), noter son expérience, et d\'envoyer un message. Le projet a été developpé en php natif avec la puissance de jquery (ajax query).',
    picture: './media/project8.JPG'
  },
]