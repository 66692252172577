import React, { Component } from 'react';
import ProgressBar from './ProgressBar';

class Languages extends Component {
    state = {
        languages: [
            {id: 1, value: "Javascript", xp:2},
            {id:3, value: "Php", xp:2},
            {id: 2, value: "Css", xp:2},
            {id: 4, value: "Python", xp:0.1}
        ],
        frameworks: [
            {id: 1, value: "React", xp:1.5},
            {id: 2, value: "Laravel", xp:1},
            {id: 3, value: "Sharepoint", xp:2},
            {id: 4, value: "Flutter", xp: 0.1},
            {id: 4, value: "Jquery", xp:2},
        ],
        deploiement: [
            {id: 1, value: "Docker", xp:1},
            {id: 2, value: "GitLab (ci/cd)", xp:0.5},
            {id: 3, value: "Kubernetes", xp:0.1}
        ],
        databases: [
            {id: 1, value: "Firebase", xp:1},
            {id: 3, value: "Mysql", xp:2},
            {id: 2, value: "postgreSQL", xp:1},
            {id: 2, value: "MongoDB", xp:0.2},
            {id: 4, value: "Redis", xp:0.1},
            {id: 5, value: "Sql server", xp:0.1}
        ],
        design: [
            {id: 1, value: "Adobe XD", xp:2},
            {id: 2, value: "Photoshop", xp:1},
            {id: 3, value: "Power Point", xp:2}
            
        ]
    }
    render() {
        let {languages, frameworks, deploiement, databases, design} = this.state;
        return (
            <div className="languagesFrameworks">
                <ProgressBar 
                    languages={languages}
                    className="languagesDisplay animate__animated animate__fadeIn"
                    title="languages"
                />
                <ProgressBar
                    languages={frameworks}
                    className="frameworksDisplay animate__animated animate__fadeIn"
                    title="frameworks et bibliothèques"
                />
                <ProgressBar 
                    languages={deploiement}
                    className="deploiementDisplay animate__animated animate__fadeIn"
                    title="Outils de deploiement"
                />
                <ProgressBar 
                    languages={databases}
                    className="databasesDisplay animate__animated animate__fadeIn"
                    title="Base de données"
                />
                <ProgressBar 
                    languages={design}
                    className="designDisplay animate__animated animate__fadeIn"
                    title="Design"
                />
            </div>
        );
    }
}

export default Languages;