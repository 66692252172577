import React from 'react';

const OtherSkills = () => {
    return (
        <div>
            <div className="otherSkills animate__animated animate__fadeIn">
                <h3>Autres expériences</h3>
                <div className="list">
                    <ul>
                        <li><i className="fas fa-check-square"></i> Anglais Courant</li>
                        <li><i className="fas fa-check-square"></i> Github</li>
                        <li><i className="fas fa-check-square"></i> SEO</li>
                        <li><i className="fas fa-check-square"></i> Méthodes agiles</li>
                    </ul>
                    <ul>
                        <li><i className="fas fa-check-square"></i> Power automate</li>
                        <li><i className="fas fa-check-square"></i> Adobe XD</li>
                        <li><i className="fas fa-check-square"></i> UX/UI</li>
                        <li><i className="fas fa-check-square"></i> Photoshop</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default OtherSkills;