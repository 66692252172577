import React from 'react';
import Navigation from '../components/Navigation';
import CopyToClipboard from 'react-copy-to-clipboard';

const Contact = () => {
    return (
        <div className="contact">
            <Navigation />
            <div className="contactContent">
                <div className="header"></div>
                <div className="contactBox">
                    <h1>Contactez-moi</h1>
                    <ul>
                        <li>
                            <i className="fas fa-map-marker-alt"></i>
                            <span>Paris</span>
                        </li>
                        <li>
                            <i className="fas fa-mobile-alt"></i>
                            <CopyToClipboard text="0783802692" >
                                <span className="clickInput" onClick={() => {
                                    alert('Telephonne copié !')
                                }}> 07 83 80 26 92</span>
                            </CopyToClipboard>
                        </li>
                        <li>
                            <i className="far fa-envelope"></i>
                            <CopyToClipboard text="gaia.khouas@gmail.com" >
                                <span className="clickInput" onClick={() => {
                                    alert('Adresse Email copiée !')
                                }}> gaia.khouas@gmail.com</span>
                            </CopyToClipboard>
                        </li>
                    </ul>
                </div>
                <div className="socialNetwork">
                    <ul>
                        <a href="https://www.linkedin.com/in/gkhouas/" target="_blank" rel="noopener noreferrer">
                            <h4>  <i className="fab fa-linkedin"></i> Linkendin</h4>
                        </a>
                        <a href="https://github.com/gaiakhouas" target="_blank" rel="noopener noreferrer">
                            <h4><i className="fab fa-github"></i> GitHub</h4>
                        </a>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Contact;