import React from 'react';

const Hobbies = () => {
    return (
        <div className="hobbies animate__animated animate__fadeIn">
            <h3>Intérêts</h3>
            <ul>
                <li className='hobby'>
                    <i className="fas fa-running" ></i>
                    <span> Course à pied</span>
                </li>
                <li className='hobby'>
                    <i className="fas fa-hiking" ></i>
                    <span> Randonnées</span>
                </li>
                <li className='hobby'>
                    <i className="fas fa-bullseye" ></i>
                    <span> Méditation</span>
                </li>
                <li className='hobby'>
                    <i className="fas fa-guitar" ></i>
                    <span> Guitare</span>
                </li>
                <li className='hobby'>
                    <i className="fas fa-paint-brush" ></i>
                    <span> Design</span>
                </li>
            </ul>
        </div>
    );
};

export default Hobbies;