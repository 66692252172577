import React, { Component } from 'react';
import { portfolioData } from '../../data/portfolioData';
import Project from './Project';

class ProjectList extends Component {
    state = {
        projects: portfolioData,
        radios: [
            { id: 1, value: "js" },
            { id: 2, value: "css" },
            { id: 3, value: "react" },
            { id: 4, value: "flutter" },
            { id: 5, value: "python" },
            { id: 6, value: "php" }
        ],
        selectedRadio: 'js'
    };

    handleRadio = (event) => {
        let radio = event.target.value;
        this.setState({ selectedRadio: radio })
    }

    render() {
        let { projects, radios, selectedRadio } = this.state;
        return (
            <div className='portfolioContent'>
                <ul className="radioDisplay">
                    {
                        radios
                            .map((radio) => {
                                return (
                                    <li key={radio.key}>
                                        <input
                                            type="radio"
                                            name="radio"
                                            defaultChecked={radio.value === selectedRadio}
                                            value={radio.value}
                                            id={radio.value}
                                            onChange={this.handleRadio}
                                        />
                                        <label htmlFor={radio.value}>{radio.value}</label>
                                    </li>

                                )

                            })
                    }
                </ul>
                <div className="projects animate__animated animate__fadeIn">
                    {
                        projects
                            .filter(item => item.languages.includes(selectedRadio))
                            .map(item => {
                                return (
                                    <Project
                                        key={item.id}
                                        item={item}
                                    />
                                )
                            })
                    }
                </div>
            </div>
        );
    }
}

export default ProjectList;