import React from 'react';
import Navigation from '../components/Navigation';
import 'animate.css'

const Home = () => {
    return (
        <div className="home">
            <Navigation />
            <div className="homeContent">

                <div className="content">
                    <h1 className="animate__animated animate__fadeInDown">Gaïa KHOUAS</h1>
                    <h2 className="animate__animated animate__fadeIn">Creative Developer</h2>
                    <div className="pdf animate__animated animate__fadeInRight">
                        <a href="./media/cv_gaia_khouas_ing.pdf" target="_blank">Télécharger CV</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;